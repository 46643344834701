<template>
  <div class="wrapper">
    <!-- 动态视频背景 -->
    <!-- <div v-if="page_pc_url !== 'http://192.168.1.138:8080'"> -->
    <!-- <div class="pf-video"> -->
    <!--      <video id="pfVideo" muted autoplay data-autoplay playsinline preload>-->
    <!--&lt;!&ndash;        <source src="https://spbg.tsmgsoce.com/ok/sis.mp4" type="video/mp4" />&ndash;&gt;-->
    <!--        <source src="@/assets/mp4/bg.mp4" type="video/mp4" />-->
    <!--      </video>-->
    <!-- </div> -->
    <header class="top">
      <img id="logo" class="logo-img" src="@/assets/images/pc/logo.png" alt />
      <div class="top-right">
        <div class="item" @click="toTg">
          <img src="@/assets/images/pc/1.png" alt="" />
        </div>
        <div class="item" @click="toPotato">
          <img src="@/assets/images/pc/2.png" alt="" />
        </div>
        <div class="item" @click="toSWTg">
          <img src="@/assets/images/pc/3.png" alt="" />
        </div>
        <div class="item" @click="toQudao">
          <img src="@/assets/images/pc/4.png" alt="" />
        </div>
        <!-- <img
          @click="download"
          class="downloadBtn"
          src="@/assets/images/pc/downloadBtn.png"
        /> -->
      </div>
    </header>

    <div class="qrBtn">
      <div
        class="qr"
        :class="downShow ? 'animate__animated animate__pulse' : ''"
      >
        <img :src="qrImg" alt />
      </div>
      <div class="btnBox">
        <img
          @click="download"
          class="iosBtn"
          src="@/assets/images/pc/iosBtn.png"
        />
        <img
          @click="download"
          class="androidBtn"
          src="@/assets/images/pc/androidBtn.png"
        />
        <div class="ucTip"></div>
      </div>
    </div>

    <div
      class="tip"
      v-show="swiperIndex == 0"
      :class="
        swiperIndex == 0
          ? roll == 'up'
            ? 'animate__animated animate__fadeInUp'
            : 'animate__animated animate__fadeInDown'
          : ''
      "
    >
      <img class="tip_1" src="@/assets/images/pc/tip_1.png" />
    </div>

    <div
      class="tip"
      v-show="swiperIndex == 1"
      :class="
        swiperIndex == 1
          ? roll == 'up'
            ? 'animate__animated animate__fadeInUp'
            : 'animate__animated animate__fadeInDown'
          : ''
      "
    >
      <img class="tip_2" src="@/assets/images/pc/tip_2.png" />
    </div>

    <div
      class="tip"
      v-show="swiperIndex == 2"
      :class="
        swiperIndex == 2
          ? roll == 'up'
            ? 'animate__animated animate__fadeInUp'
            : 'animate__animated animate__fadeInDown'
          : ''
      "
    >
      <img class="tip_3" src="@/assets/images/pc/tip_3.png" />
    </div>

    <van-swipe
      class="swipe"
      ref="swipe"
      duration="1000"
      autoplay="3000"
      :show-indicators="false"
      vertical
      loop
      @change="swipeChange"
    >
      <van-swipe-item>
        <div class="contentSwipe1">
          <!--                <div class="left">-->
          <!--                    <img class="tip" src="@/assets/images/pc/tip_1.png">-->
          <!--&lt;!&ndash;                    <div class="qrBtn">&ndash;&gt;-->
          <!--&lt;!&ndash;                        <div class="qr">&ndash;&gt;-->
          <!--&lt;!&ndash;                            <img :src="qrImg" alt />&ndash;&gt;-->
          <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                        <div class="btnBox">&ndash;&gt;-->
          <!--&lt;!&ndash;                            <img class="androidBtn" src="@/assets/images/pc/androidBtn.png">&ndash;&gt;-->
          <!--&lt;!&ndash;                            <img class="iosBtn" src="@/assets/images/pc/iosBtn.png">&ndash;&gt;-->
          <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
          <!--                </div>-->
          <div class="right">
            <img src="@/assets/images/pc/people_1.webp" />
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="contentSwipe2">
          <!--                  <div class="left">-->
          <!--                      <img class="tip" src="@/assets/images/pc/tip_2.png">-->
          <!--&lt;!&ndash;                      <div class="qrBtn">&ndash;&gt;-->
          <!--&lt;!&ndash;                          <div class="qr">&ndash;&gt;-->
          <!--&lt;!&ndash;                              <img :src="qrImg" alt />&ndash;&gt;-->
          <!--&lt;!&ndash;                          </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                          <div class="btnBox">&ndash;&gt;-->
          <!--&lt;!&ndash;                              <img class="androidBtn" src="@/assets/images/pc/androidBtn.png">&ndash;&gt;-->
          <!--&lt;!&ndash;                              <img class="iosBtn" src="@/assets/images/pc/iosBtn.png">&ndash;&gt;-->
          <!--&lt;!&ndash;                          </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                      </div>&ndash;&gt;-->
          <!--                  </div>-->
          <div class="right">
            <img src="@/assets/images/pc/people_2.webp" />
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="contentSwipe3">
          <!--                  <div class="left">-->
          <!--                      <img class="tip" src="@/assets/images/pc/tip_3.png">-->
          <!--&lt;!&ndash;                      <div class="qrBtn">&ndash;&gt;-->
          <!--&lt;!&ndash;                          <div class="qr">&ndash;&gt;-->
          <!--&lt;!&ndash;                              <img :src="qrImg" alt />&ndash;&gt;-->
          <!--&lt;!&ndash;                          </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                          <div class="btnBox">&ndash;&gt;-->
          <!--&lt;!&ndash;                              <img class="androidBtn" src="@/assets/images/pc/androidBtn.png">&ndash;&gt;-->
          <!--&lt;!&ndash;                              <img class="iosBtn" src="@/assets/images/pc/iosBtn.png">&ndash;&gt;-->
          <!--&lt;!&ndash;                          </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                      </div>&ndash;&gt;-->
          <!--                  </div>-->
          <div class="right">
            <img src="@/assets/images/pc/people_3.webp" />
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <!--    <main class="content" id="content">-->
    <!--        <div class="left">-->
    <!--            <img class="tip" src="@/assets/images/pc/tip_1.png">-->
    <!--            <div class="qrBtn">-->
    <!--                <div class="qr">-->
    <!--                    <img :src="qrImg" alt />-->
    <!--                </div>-->
    <!--                <div class="btnBox">-->
    <!--                    <img class="androidBtn" src="@/assets/images/pc/androidBtn.png">-->
    <!--                    <img class="iosBtn" src="@/assets/images/pc/iosBtn.png">-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div class="right">-->
    <!--            <img src="@/assets/images/pc/people_1.png">-->
    <!--        </div>-->
    <!--    </main>-->
    <footer class="footer">
      <span
        >温馨提示：应用内包含成人内容，下载前请确认您已满18岁，本平台主要收益为广告赞助，且保证APP安全无毒，如遇报毒提醒请忽略！</span
      >
      <!--      <img id="tip" src="@/assets/images/pc/bottom_tip.png" alt />-->
    </footer>
    <!-- </div>
    <div v-else>提示：该应用只支持手机端！</div> -->
  </div>
</template>
<script>
export default {
  props: ["qrImg", "os"],
  data() {
    return {
      showLogo: false,
      showVoice: false,
      et: "",
      src: require("@/assets/images/pc/closev.png"),
      voiceList: {
        src_open: require("@/assets/images/pc/openv.png"),
        src_close: require("@/assets/images/pc/closev.png"),
      },
      isLock: false,
      downShow: false,
      swiperIndex: 0,
      roll: "down",
      issSide: false,
    };
  },
  computed: {
    show() {
      return this.showLogo;
    },
  },
  created() {
    // this.handleImage();
  },
  mounted() {
    // chrome and ie（谷歌和IE）
    window.addEventListener("mousewheel", this.handleScroll, false);
    // firefox（火狐）
    window.addEventListener("DOMMouseScroll", this.handleScroll, false);

    // const video = document.getElementById("pfVideo");
    // if (video) {
    //   video.addEventListener(
    //     "ended",
    //     () => {
    //       video.setAttribute("style", "visibility:hidden");
    //       this.showLogo = true;
    //       setTimeout(() => {
    //         video.setAttribute("style", "visibility:visible");
    //
    //         video.play();
    //       }, 0);
    //       // setTimeout(() => {
    //       //   this.showLogo = !this.showLogo;
    //       // }, 4500);
    //     },
    //     false
    //   );
    // }
  },
  destroyed() {
    clearTimeout(this.et);
  },
  methods: {
    swipeChange(index) {
      if (!this.issSide) {
        this.roll = "up";
      }
      this.swiperIndex = index;
    },
    download() {
      this.downShow = true;
      setTimeout(() => {
        this.downShow = false;
      }, 1000);
    },
    getApkInfo(type) {
      this.$emit("getApkInfo", type);
    },
    swipeNext() {
      if (!this.isLock) {
        this.roll = "up";
        this.issSide = true;
        this.isLock = true;
        this.$refs.swipe.next();
        setTimeout(() => {
          this.isLock = false;
          this.issSide = false;
        }, 1000);
      }
    },
    swipePrev() {
      if (!this.isLock) {
        this.roll = "down";
        this.issSide = true;
        this.isLock = true;
        this.$refs.swipe.prev();
        setTimeout(() => {
          this.isLock = false;
          this.issSide = false;
        }, 1000);
      }
    },
    //顶部声音开关
    voiceChange() {
      let myvideo = document.getElementById("pfVideo");
      switch (this.showVoice) {
        case false:
          myvideo.muted = false;
          this.showVoice = true;
          this.src = this.voiceList.src_open;
          break;
        case true:
          myvideo.muted = true;
          this.showVoice = false;
          this.src = this.voiceList.src_close;
          break;
      }
    },
    toTg() {
      window.open("https://t.me/sis999999");
    },
    toPotato() {
      window.open("https://dlj199.org/sis66");
    },
    toSWTg() {
      window.open("https://t.me/mu02guang");
    },
    toQudao() {
      window.open("https://t.me/huansong_nangua");
    },
    handleImage() {
      //logo
      let logoUrl =
        "https://yuan.dhuqh.com/bf211222/image/1l8/12d/n4/1ge/33bfff1b0b60ed0c8e4ff4b28647af89.png";
      changeImg(logoUrl).then((res) => {
        document.getElementById("logo").src = `${res}`;
      });
      //content
      // let contentUrl =
      //   "https://yuan.dhuqh.com/image/1b4/124/1vk/2st/278ad641ea12266ff51ff486d18ec33d.png";
      // changeImg(contentUrl).then(res => {
      //   document.getElementById(
      //     "content"
      //   ).style.backgroundImage = `url(${res})`;
      // });
      //tip
      let tipUrl = "https://yuan.dhuqh.com/2022_ussnkone_warning.png";
      changeImg(tipUrl).then((res) => {
        document.getElementById("tip").src = `${res}`;
      });
    },
    handleScroll(event) {
      var delta = 0;
      if (!event) event = window.event;
      if (event.wheelDelta) {
        delta = event.wheelDelta / 120;
        if (window.opera) delta = -delta;
      } else if (event.detail) {
        delta = -event.detail / 3;
      }
      if (delta < 0) {
        this.swipeNext();
        // console.log("向下滚动")
      } else {
        this.swipePrev();
        // console.log("向上滚动")
      }
    },
  },
};
//图片解密方法
export const changeImg = async (url) => {
  let encoder = new TextEncoder();
  let KEY = encoder.encode("2019ysapp7527");
  return new Promise((resolve) => {
    let oReq = new XMLHttpRequest();
    oReq.open("GET", url, true);
    oReq.responseType = "arraybuffer";
    oReq.onload = function() {
      var arrayBuffer = oReq.response; // 注意:不是oReq.responseText
      if (arrayBuffer) {
        var bytes = new Uint8Array(arrayBuffer);
        for (let i = 0; i < 100; i++) {
          bytes[i] ^= KEY[i % KEY.length];
        }
        const blob = new Blob([bytes], {
          type: "image/png",
        });
        const url = URL.createObjectURL(blob);
        resolve(url);
      }
    };
    oReq.send(null);
  });
};
</script>

<style lang="scss" scoped>
.fade-enter-active {
  //类名：隐藏到显示过程所需要的时间
  animation: bounce-in 0.5s;
}

.fade-leave-active {
  //类名：显示到隐藏过程所需要的时间
  animation: bounce-in 0.4s reverse; //reverse表示和隐藏到显示动画相反
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.wrapper {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-image: url("../../../assets/images/pc/bg.png");
  background-size: 100% 100%;
  //动态背景设置
  .pf-video {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: -999;
    //background-position: 100% 100%;
    //background-repeat: no-repeat;
    //background-size: 1450px 2666px;
    background-image: url("../../../assets/images/pc/bg.webp");
    background-size: 100% 100%;

    video {
      width: 100vw;
      min-width: 280px;
      height: 100vh;
      min-height: 800px;
      object-fit: cover;
      overflow: hidden;
    }
  }

  //顶部
  .top {
    width: 100%;
    height: 6.5vw;
    position: relative;
    background: rgba(0, 0, 0, 0.6);
    // background: rgba($color: #fff, $alpha: 0.1);
    //background: url("../../../assets/images/pc/top_bg.png") no-repeat;
    //background-size: cover;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 14vw;
    padding-right: 6vw;

    .logo-img {
      //position: absolute;
      //top: 50%;
      //left: 1.5rem;
      //transform: translate(0, -50%);
      height: 4vw;
      ///*no*/
    }

    .top-right {
      display: flex;
      color: #fff;
      .item {
        margin-left: 20px;
        width: 44px;
        cursor: pointer;
        font-size: 10px;
        text-align: center;
        img {
          width: 100%;
          margin-bottom: 5px;
        }
      }
    }
  }

  .qrBtn {
    display: flex;
    align-items: center;
    //margin-left: 38px;
    //margin-top: 31.8px;
    z-index: 99;

    position: absolute;
    left: 17.5%;
    top: 50%;

    .qr {
      //border: solid 2px #FFFFFF;
      //border-radius: 15px;
      background: #ffffff;

      img {
        width: 250px;
        height: 250px;
        //border: solid 10px #3D3D3D;
        //border-radius: 15px;
      }
    }

    .btnBox {
      display: flex;
      flex-direction: column;
      margin-left: 51px;

      .iosBtn {
        width: 283px;
        height: 74px;
      }
      .androidBtn {
        margin-top: 30px;
        width: 283px;
        height: 74px;
      }
      .ucTip {
        width: 250px;
        height: 24px;
        background-image: url("../../../assets/images/pc/ucTip.png");
        background-size: 100% 100%;
        margin-top: 30px;
      }

      img {
        //cursor:pointer;
        height: 3vw;
      }
    }
  }

  .tip {
    display: flex;
    align-items: center;
    z-index: 99;

    position: absolute;
    left: 11.5%;
    top: 28%;

    .tip_1 {
      height: 178.2px;
      margin-left: 70px;
    }

    .tip_2 {
      height: 162.9px;
    }

    .tip_3 {
      height: 172.9px;
      margin-left: 100px;
    }
  }

  //内容区
  .swipe {
    //background: url("../../../assets/images/pc/content.png") no-repeat;
    // background: url("") no-repeat;
    //  background: red;
    background-size: 100%;
    position: absolute;
    right: 201px;
    //left: 15%;
    top: 18%;
    //bottom: 20%;
    //transform: translate(-85%, -15%);
    //width: 1550px;
    width: 100%;
    display: flex;
    justify-content: right;
    //height: 480px;

    ::v-deep .van-swipe__indicators {
      position: absolute;
      right: 2%;
      top: 5.5%;
      left: auto;
    }

    ::v-deep .van-swipe__indicator {
      width: 10px;
      height: 10px;
      background: rgba(255, 255, 255, 0.7);
    }

    ::v-deep .van-swipe__indicator--active {
      background: rgb(255, 255, 255);
      height: 40px;
      border-radius: 90px;
    }

    .contentSwipe1 {
      display: flex;
      //margin-left: 14vw;

      //.left{
      //    margin-top: 124px;
      //
      //    .tip{
      //        height: 178.2px;
      //    }
      //}

      .right {
        margin-left: 36px;
        // margin-top: -50px;
        img {
          height: 728px;
          width: 707px;
        }
      }
    }

    .contentSwipe2 {
      display: flex;
      //margin-left:  12vw;
      //
      //.left{
      //    margin-top: 124px;
      //
      //    .tip{
      //        height: 162.9px;
      //    }
      //}

      .right {
        margin-left: 36px;
        img {
          height: 728px;
          width: 707px;
        }
      }
    }

    .contentSwipe3 {
      display: flex;
      //margin-left: 16.5vw;

      //.left{
      //    margin-top: 124px;
      //
      //    .tip{
      //        height: 172.9px;
      //    }
      //}

      .right {
        margin-left: 36px;
        img {
          height: 728px;
          width: 707px;
        }
      }
    }
  }

  //顶部
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    background: url("../../../assets/images/pc/footer.png") no-repeat;
    background-size: 100% 100%;
    // left: 50%;
    // bottom: 0rem;
    // transform: translate(-50%);
    color: #ffffff;
    font-size: 16px;
    //background: rgba(255,255,255,0.7);
    height: 222px;
    width: 100%;
    padding-top: 144px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 1620px;
      height: 222px;
      //opacity: 0.8;
    }
  }

  @media screen and (max-width: 750px) {
    .mark {
      min-height: 2rem;
    }

    .pf-inner {
      height: 2rem;
    }
  }
}
</style>
